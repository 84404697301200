<template>
  <AuthLayout v-if="step === -1">
    <div class="auth register-mentor-auth">
      <h1 class="auth__title">{{ title }}</h1>
      <div class="auth__body">
        <form @submit.prevent="nextStep">
          <EmailInput
            v-model="form.email"
            :error="errors.email"
          />
          <template v-if="role === 'mentor'">
            <div class="inputs-grid">
              <ContactInput
                v-model:type="form.contact_type"
                v-model="form.contact"
                :error-type="errors.contact_type"
                :error="errors.contact"
              />
            </div>
            <MentorContactNotice />
          </template>
          <PasswordAtomInput
            name="Придумайте пароль"
            v-model="form.password"
            :error="errors.password"
            desc="Буквы, цифры, не менее 8 символов"
          />
          <PasswordAtomInput
            name="Подтвердите пароль"
            v-model="form.password_confirmation"
            :error="errors.password_confirmation"
          />
          <BaseButton big color-primary :class="{ disabled: !stepCompletion, pending: loading }">Продолжить</BaseButton>
          <UserAgreementNotice />
          <BaseButton class="auth__back" transparent :to="{ name: 'auth-login' }" replace><IconArrowLeft />Назад</BaseButton>
        </form>
      </div>
    </div>
  </AuthLayout>
  <main v-else class="register-mentor">
    <div class="horiz-container">
      <FancyBreadcrumbs :links="[
        { name: 'Авторизация', to: { name: 'auth-login' } },
        { name: title, to: { name: 'auth-register-advanced', params: { role } } }
      ]" class="register-mentor__breadcrumbs" />
      <div class="page-title register-mentor__title">{{ title }}</div>
      <Tabs bottom fluid progress scrollable :tabs="[
        { name: 'Основная информация', required: true },
        { name: 'Скиллы', required: true },
        { name: 'О вас' },
        { name: 'Образование и работа' }
      ]" v-model="step" class="register-mentor__steps" />
      <div class="register-mentor__body default-grid row-gap">
        <div class="register-mentor__sidebar" :class="{ 'first-step': step === 0 }">
          <UploadAvatar :input="true" :button="true" :is-editable="step === 0" v-model="form.avatar" :error="errors.avatar" />
        </div>
        <div class="register-mentor__content">
          <div class="register-mentor__step">
            <ProfileFormHeader v-if="stepTitle" :editing="false" :hint="stepHint">{{ stepTitle }}</ProfileFormHeader>

            <form @submit.prevent="nextStep" class="register-mentor__step-body">

              <template v-if="step === 0">
                <div class="inputs-grid">
                  <AtomInput
                    name="Имя"
                    v-model="form.first_name"
                    :error="errors.first_name"
                  />
                  <AtomInput
                    name="Фамилия"
                    v-model="form.last_name"
                    :error="errors.last_name"
                  />
                  <CityInput
                    class="input-col-2"
                    v-model="form.city_id"
                    :error="errors.city_id"
                  />
                  <ProfilePriceInput
                    v-if="role === 'mentor'"
                    v-model="form.price"
                    :error="errors.price"
                  />
                  <AtomInput
                    v-else-if="role === 'specialist'"
                    class="input-col-2"
                    name="Почасовая ставка"
                    symbol="₽"
                    v-model="form.price"
                    :error="errors.price"
                    :process-field="processField"
                  />
                </div>
                <ProfileLanguagesInput
                  single-col
                  v-model="form.languages"
                />
              </template>

              <template v-if="step === 1">
                <ProfileSkillsInput
                  single-col
                  v-model="form.skills"
                  :errors="errors"
                />
              </template>

              <template v-if="step === 2">
                <ProfileField single-col name="О себе" class="inputs-grid">
                  <ProfileAboutInput
                    class="input-col-2"
                    v-model="form.about"
                    :error="errors.about"
                  />
                  <AtomInput
                    class="input-col-2"
                    type="textarea"
                    name="Подробное описание"
                    v-model="form.general_about"
                    :error="errors.general_about"
                    :desc="'Опишите, что вы умеете делать, и чему можете научить.' + (role === 'mentor' ? ' Это важно для менти — они внимательно изучают профили менторов.' : '')"
                  />
                </ProfileField>
                <ProfileRequestsInput
                  single-col
                  v-model="form.requests"
                  :errors="errors"
                />
                <ProfileContactsInput
                  single-col
                  v-model="form.contacts"
                  :errors="errors"
                />
              </template>

              <div v-if="step === 3">
                <ProfileSection title="Работа и проекты" hint="Необязательно" class="flex-mobile-separator">
                  <ProfileExperienceInput
                    single-col
                    v-model="form.projects"
                    :errors="errors"
                  />
                </ProfileSection>
                <ProfileSection title="Образование" hint="Необязательно" class="flex-mobile-separator">
                  <ProfileEducationInput
                    single-col
                    v-model="form.educations"
                    :errors="errors"
                  />
                </ProfileSection>
                <ProfileSection title="Профильные форумы и мероприятия" hint="Необязательно" class="flex-mobile-separator">
                  <ProfileEventsInput
                    single-col
                    v-model="form.events"
                    :errors="errors"
                  />
                </ProfileSection>
              </div>

            </form>
          </div>

          <div class="register-mentor__step-actions">
            <BaseButton transparent @click="previousStep"><IconChevronLeft class="icon" />Назад</BaseButton>
            <BaseButton color-primary @click="nextStep" :class="{ disabled: !stepCompletion, pending: loading }">
              <template v-if="step < 3">Продолжить<IconChevronRight class="icon" /></template>
              <template v-else>Завершить регистрацию</template>
            </BaseButton>
          </div>
          <div v-if="error" class="register-mentor__error">{{ error }}</div>
        </div>
      </div>
    </div>
    <Navbar>
      <BaseButton small transparent @click="previousStep"><IconChevronLeft class="icon" />Назад</BaseButton>
      <BaseButton v-if="step < 3" small transparent @click="nextStep" :class="{ disabled: !stepCompletion, pending: loading }">Вперед<IconChevronRight class="icon" /></BaseButton>
      <BaseButton v-else small color-primary @click="nextStep" :class="{ disabled: !stepCompletion, pending: loading }">Завершить регистрацию</BaseButton>
    </Navbar>
  </main>
</template>

<script setup>
import useForm from '~/composables/useForm'
import { IconChevronRight, IconChevronLeft, IconArrowLeft } from '@tabler/icons-vue'
import { register, registerValidation } from '@/api/auth'
import cloneDeep from 'lodash.clonedeep'
import contactTypes from '~/data/contactTypes'

const router = useRouter()
const route = useRoute()

const processField = value => value.toString().replace(/\D/g, '')

const error = ref(null)
const step = ref(-1)
provide('profile.editable', ref(true))

const role = ref(route.params.role)

const title = computed(() => `Стать ${{ mentor: 'ментором', specialist: 'специалистом' }[role.value]}`)
useHead({ title })

const stepTitle = computed(() => ['Основная информация', 'Скиллы', 'О вас', null][step.value])
const stepHint = computed(() => step.value === 2 ? 'Необязательно для заполнения' : null)

const areFilled = (...inputs) => inputs.map(i => form.value[i]).every(i => !!i)
const stepCompletion = computed(() => ({
  [-1]: () => areFilled('email', ...(role.value === 'mentor' ? ['contact_type', 'contact'] : []), 'password', 'password_confirmation'),
  0: () => areFilled('avatar', 'first_name', 'last_name', 'city_id', 'price'),
  1: () => form.value.skills.filter(s => s.skill_id || s.skill_name).length
})[step.value]?.() ?? true)

function previousStep () {
  if (!loading.value) step.value -= 1
}

const stepFields = computed(() => ({
  [-1]: ['email', ...(role.value === 'mentor' ? ['contact_type', 'contact'] : []), 'password', 'password_confirmation'],
  0: ['avatar', 'first_name', 'last_name', 'city_id', 'price', 'languages'],
  1: ['skills'],
  2: ['about', 'general_about', 'requests', 'contacts'],
  3: ['projects', 'educations', 'events']
})[step.value])
async function nextStep () {
  error.value = null
  if (!stepCompletion.value) return
  if (Object.hasOwn(await submit(stepFields.value), 'errors')) return (error.value = 'Заполните все поля верно')

  if (step.value < 3) return step.value++
  if (Object.hasOwn(await submit(), 'errors')) return (error.value = 'Заполните все поля верно')

  localStorage.setItem('registerEmail', form.value.email)
  await router.replace({ name: 'auth-confirm' })
}

const {
  form,
  errors,
  loading,
  submit
} = useForm((data, validate = null) => {
  if (validate === null) return register(data)

  data = cloneDeep(data)
  Object.keys(data).forEach(k => {
    if (!validate.includes(k)) delete data[k]
  })
  validate.forEach(k => {
    if (!Object.hasOwn(data, k)) data[k] = null
  })
  data.type = role.value
  return registerValidation(data)
})
watchImmediate(role, value => form.value.type = value)
form.value.contact_type = contactTypes[0].id
form.value.languages = new Set()
form.value.skills = []
form.value.contacts = {}
form.value.projects = []
form.value.educations = []
form.value.events = []

const referral = useLocalStorage('referral', null)
watch(referral, value => value && (form.value.referral = value), { immediate: true })
</script>

<style lang="scss">
.register-mentor-auth {
  margin-bottom: -80px;
}

.register-mentor {
  @media (max-width: 920px) {
    padding-top: 0;
  }
  &__breadcrumbs {
    @media (max-width: 920px) {
      display: none !important;
    }
  }
  &__title {
    margin-bottom: 37px;
    @media (max-width: 920px) {
      display: none;
    }
  }
  &__steps {
    margin-bottom: 37px;
    background: var(--color-bg);
    z-index: 99;
    @media (max-width: 920px) {
      position: sticky !important;
      top: 60px;
      margin-left: -15px;
      width: calc(100% + 30px) !important;
    }
  }
  &__sidebar {
    grid-column: span 3;
    @media (max-width: 920px) {
      grid-column: span 12;
      display: flex;
      flex-direction: column;
      align-items: center;
      &:not(.first-step) {
        display: none;
      }
    }
  }
  &__content {
    grid-column: span 9;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 920px) {
      grid-column: span 12;
    }
  }
  &__step-body {
    display: flex;
    flex-direction: column;
    gap: 32px;
    .inputs-grid .profile-field {
      grid-column: span 2;
    }
  }
  &__step-actions {
    display: flex;
    justify-content: flex-end;
    gap: 32px;
    margin-top: 32px;
    @media (max-width: 920px) {
      display: none;
    }
  }

  .profile-price-form__helper {
    margin-top: 0;
  }

  &__error {
    color: var(--color-error);
    text-align: right;
    margin-top: 10px;
  }
}
</style>
